import FileSaver from 'file-saver';
import JSZip from 'jszip';
import React, {Component} from 'react';
import { Dictionary } from '../models';
import Mt940Reader from '../services/Mt940Reader';

interface Mt940MergerProps {
}

interface Mt940MergerState {
    isProcessing: boolean,
    loadedFiles: number,
    loadedSections: Dictionary<string[]>
}

export default class Mt940Merger extends Component<Mt940MergerProps, Mt940MergerState> {

    constructor(props: Mt940MergerProps) {
        super(props);
        this.state = {
            isProcessing: false,
            loadedFiles: 0,
            loadedSections: {}
        };
    }

    readFileContents = async (file: File) => {
        return new Promise((resolve, reject) => {
            let fileReader = new FileReader();
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = reject;
            fileReader.readAsText(file, "ISO-8859-2");
        });
    }

    readAllFiles = async (files: File[]) => {
        const results = await Promise.all(files.map(async (file) => {
            const fileContents = await this.readFileContents(file);
            return Mt940Reader(fileContents as string);
        }));
        return results;
    }

    handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ isProcessing: true });
        if (e.target.files == null) {
            return;
        }
        let files: File[] = Array.from(e.target.files);
        this.readAllFiles(files).then(result => {
            const accounts: Dictionary<string[]> = {};
            result.forEach(section => {
                section.forEach(entry => {
                    if (!accounts[entry.accountNumber]) { 
                        accounts[entry.accountNumber] = [];
                    }
                    accounts[entry.accountNumber].push(entry.data);
                });
            });
            this.setState({ isProcessing: false, loadedFiles: result.length, loadedSections: accounts });
        }).catch(err => {
            this.setState({ isProcessing: false, loadedFiles: 0, loadedSections: {} });
            alert(err);
        });
    }

    renderDetails(): JSX.Element {
        const { loadedFiles, loadedSections } = this.state;
        if (loadedFiles === 0) {
            return <div>No files imported.</div>
        }
        return <div>
            <div>
                Imported { loadedFiles } files with { Object.keys(loadedSections).length } different accounts.
            </div>
            <table>
            <thead>
                <tr>
                    <th>Account</th>
                    <th>Number of data blocks</th>
                </tr>
            </thead>
            <tbody>
            {Object.keys(loadedSections).map(key => {
                const d = loadedSections[key];
                return <tr key={key}>
                    <td>{ key }</td>
                    <td>{ d.length }</td>
                </tr>
            })}
            </tbody>
        </table>
        </div>;
    }

    downloadFiles = () => {
        const { loadedSections } = this.state;
        if (Object.keys(loadedSections).length === 0) {
            return;
        }
        let zip = new JSZip();
        Object.keys(loadedSections).forEach(key => {
            zip.file(key + ".txt", loadedSections[key].join('\n'));
        });
        zip.generateAsync({type: "blob"}).then(function(content) {
            FileSaver.saveAs(content, "download.zip");
        });
    }

    render(): JSX.Element {
        const { isProcessing } = this.state;
        return <div>
                <h2>Step 1: Select files</h2>
                <input type="file" multiple onChange={(e) => this.handleUpload(e)}/>
                <h2>Step 2: Processing</h2>
                { isProcessing ? "Processing files..." : this.renderDetails() }
                <h2>Step 3: Download files</h2>
                <button onClick={this.downloadFiles}>Download</button>
            </div>        
    }
}