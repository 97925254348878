import React from 'react';
import './App.css';
import Mt940Merger from './components/Mt940Merger';

function App() {
  return (
    <div className="App">
      <Mt940Merger />
    </div>
  );
}

export default App;
