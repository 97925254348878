import { Mt940Section } from "../models";

const MT940_ACCOUNT_LINE_KEY = ":25:";
const MT940_DATA_BLOCK_START = String.fromCharCode(1);
const MT940_DATA_BLOCK_END = String.fromCharCode(45) + String.fromCharCode(3);

export default function Mt940Reader(data: string): Mt940Section[] {
    const sections = ReadSections(data).map(section => ParseSection(section));
    return sections;
}

function ReadSections(data: string): string[] {
    const sections: string[] = [];
    let from = data.indexOf(MT940_DATA_BLOCK_START);
    let to = data.indexOf(MT940_DATA_BLOCK_END);
    while (from >= 0 && to > 0) {        
        sections.push(data.substring(from, to + MT940_DATA_BLOCK_END.length));
        data = data.substring(to + MT940_DATA_BLOCK_END.length);        
        from = data.indexOf(MT940_DATA_BLOCK_START);
        to = data.indexOf(MT940_DATA_BLOCK_END);        
    }
    return sections;
}

function ParseSection(data: string): Mt940Section {
    const lines = data.split('\n');
    const accountNumber = lines.filter(line => line.startsWith(MT940_ACCOUNT_LINE_KEY)).map(line => line.substring(MT940_ACCOUNT_LINE_KEY.length));
    const section: Mt940Section = {
        accountNumber: accountNumber[0],
        data: data
    };
    return section;
}